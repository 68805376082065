import * as React from 'react';
import { useEffect, useState } from 'react';
import closeIcon from './icons/close.svg'
import settingsIconSVG from "./icons/common-cog.svg";

export const SettingsIcon = (props) => {
  const {
    toggleCallback,
    onSettingsClick,
    selectedSettings,
    setSelectedSettings
  } = props;
  return <div className='align-self-center'>
    <button className='nv-recommender-settings-button' onClick={() => {
      onSettingsClick()
        .then(data => {
          setSelectedSettings(data ? data :selectedSettings);
        })
        .then(toggleCallback)
    }}>
      <img src={settingsIconSVG} alt=""/>
    </button>
  </div>
};

export const SettingsForm = (props) => {
  const staticLinks = {
    privacyPolicy: 'https://www.nvidia.com/en-us/about-nvidia/privacy-policy/',
    cookiePolicy: 'https://www.nvidia.com/en-us/about-nvidia/cookie-policy/',
  }
  const {interestAreas = [], selectedInterestAreas = [], onSaveCallback, toggleCallback, dialogId} = props;
  const [selectedItems, setSelectedItems] = useState(new Map());

  useEffect(() => {
    const selectedItemsMap = new Map();
    selectedInterestAreas.forEach(item => selectedItemsMap.set(item, item));
    setSelectedItems(selectedItemsMap)
  }, []);

  return (
    <React.Fragment>
      <dialog id={dialogId} className="nv-modal nv-modal-cozy">
        <div className="nv-modal-header">
          <div className="nv-modal-title">Select Your Interests</div>
          <div className="nv-modal-close-button" onClick={toggleCallback}></div>
        </div>
        <div className="nv-modal-body">
          <p className='p--large text-center mb-0'>Refine your recommendations for more personalized results.</p>

          <div className='nv-recommender-settings'>
            {interestAreas.map(item => {
              return <div className="form-check" key={item.value}>
                <input className="form-check-input"
                       type="checkbox"
                       value={item.value}
                       onChange={(e) => {
                         const originalValues = new Map(selectedItems);
                         if (e.target.checked) {
                           originalValues.set(e.target.value, e.target.value);
                         } else {
                           originalValues.delete(e.target.value);
                         }
                         setSelectedItems(originalValues);
                       }}
                       checked={selectedItems.has(item.value)}
                       id={`recommender-option-${item.value}`}
                />
                <label className="form-check-label" htmlFor={`recommender-option-${item.value}`}>
                  {item.label}
                </label>
              </div>;
            })}
          </div>
        </div>

        <div className="nv-modal-footer">
          <div className="nv-recommender-settings__links">
            <a href={staticLinks.privacyPolicy} target='_blank'>Privacy Policy</a>
            <span className='footer-separator'>|</span>
            <a href={staticLinks.cookiePolicy} target='_blank'>Cookie Policy</a>
          </div>
          <button id="btn-settings-cancel" className="nv-cta-secondary-alt" onClick={toggleCallback}>Cancel</button>
          <button id="btn-settings-save" className="nv-cta-primary" onClick={() => {
            toggleCallback();
            onSaveCallback(selectedItems);
          }}>Save</button>
        </div>
      </dialog>
    </React.Fragment>
  );
};
