import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import copyIcon from './code-container/icons/copy.svg';
import arrowIcon from './code-container/icons/chevron-down.svg';
import externalLinkIcon from './code-container/icons/arrow-up-right-from-square.svg';
import classnames from "classnames";
import { Highlight, themes } from 'prism-react-renderer';

const containerLabel = (lang) => {
  if (!lang) {
    return 'Plain Text';
  }

  switch (lang.toLowerCase()) {
    case 'csharp':
      return 'C#';
    case 'plain_text':
      return 'Plain Text';
    case 'cpp':
      return 'C++';
    default:
      return lang.charAt(0).toUpperCase() + lang.slice(1);
  }
}

/**
 * Line number component.
 *
 * @param {Object} props
 * @param {Boolean} props.visible
 * @param {Number} props.number
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
const LineNumber = (props) => {
  const {visible, number} = props;
  if (!visible) {
    return null;
  }

  return <span className='line-number'>{number + 1}</span>
}

/**
 * Code block component.
 *
 * @param {Object} props
 * @param {String} props.code
 * @param {String} props.language
 * @param {Boolean} props.showLineNumbers
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CodeBlock = (props) => {
  const {
    code,
    language,
    showLineNumbers,
  } = props;
  return (
    <Highlight code={code} language={language} theme={themes.oneLight}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={style}>
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({line, key: i})}>
            <LineNumber visible={showLineNumbers} number={i}/>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({token, key})} />
            ))}
          </div>
        ))}
      </pre>
      )}
    </Highlight>
  );
};

/**
 * Link component.
 *
 * @param {String} text
 * @param {String} url
 * @param {String} target
 * @param {String} icon
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
const Link = ({text, url, target, icon}) => {
  if (!text || !url) {
    return null;
  }

  return (
    <a href={url} target={target || '_blank'}>
      <img src={icon} alt={text}/> {text}
    </a>
  );
}

const CodeContainer = (props) => {
  const {
    pLanguage,
    showLineNumbers,
    wrapLongLines,
    rows = 10,
    show_collapse_expand_toggle: showExpandButton = false,
    expanded: expandedByDefault,
    class: className
  } = props;
  const {
    link_1_text,
    link_1_url,
    link_1_target,
    link_2_text,
    link_2_url,
    link_2_target
  } = props;
  const tooltipRef = useRef();
  const containerContentRef = useRef();
  const [expanded, setExpanded] = useState(expandedByDefault === true);
  const LINE_HEIGHT = 24;
  const CONTAINER_PADDING = 15;
  const TOOLTIP_VISIBILITY_TIME = 1500;

  const handleExpanded = (expanded) => {
    if (expanded) {
      const val = `${parseInt(rows) * LINE_HEIGHT + CONTAINER_PADDING}px`;
      containerContentRef.current.style.setProperty('--nv-code-snippet-viewport-height', val);
    } else {
      containerContentRef.current.style.setProperty('--nv-code-snippet-viewport-height', '100vh');
    }
  }

  const rootCss = ['nv-code-container'];
  if (className) {
    className.split(' ').forEach(item => {
      if (rootCss.includes(item)) {
        return;
      }
      rootCss.push(item);
    });
  }
  const css = {
    root: classnames(rootCss, {
      'nv-code-container--wrap-lines': wrapLongLines,
    }),
    expanded: classnames({
      'nv-code-container__expand-button': true,
      'expanded': expanded,
      'd-none': !showExpandButton
    }),
  };

  useEffect(() => {
    if (containerContentRef.current) {
      const val = `${parseInt(rows) * LINE_HEIGHT + CONTAINER_PADDING}px`;
      containerContentRef.current.style.setProperty('--nv-code-snippet-viewport-height', val);
      containerContentRef.current.style.setProperty('--nv-code-snippet-viewport-height-initial', val);

      if (expanded) {
        containerContentRef.current.style.setProperty('--nv-code-snippet-viewport-height', '100vh');
      }
    }
  }, []);

  const handleOnCopy = (text, result) => {
    const activeClassName = 'active';
    tooltipRef.current.classList.add(activeClassName);
    setTimeout(() => {
      tooltipRef.current.classList.remove(activeClassName);
    }, TOOLTIP_VISIBILITY_TIME);
  };

  const onExpandButtonClicked = () => {
    handleExpanded(expanded);
    setExpanded(!expanded);
  };

  return (
    <div className={css.root}>
      <div className='nv-code-container__header'>
        <div className='nv-code-container__title'>{containerLabel(pLanguage)}</div>
        <div className='nv-code-container__actions'>
          <Link text={link_1_text} url={link_1_url} target={link_1_target} icon={externalLinkIcon}/>
          <Link text={link_2_text} url={link_2_url} target={link_2_target} icon={externalLinkIcon}/>
          <CopyToClipboard text={props.childrenContent || ''} onCopy={handleOnCopy}>
            <button>
              <div className='nv-code-container__tooltip' ref={tooltipRef}>Copied</div>
              <img src={copyIcon} alt='Copy' title='Copy'/>
              Copy
            </button>
          </CopyToClipboard>
        </div>
      </div>
      <div className='nv-code-container__content' ref={containerContentRef}>
        {props.childrenContent === undefined ? <span data-chld='true'>{props.children}</span> : null}
        {props.childrenContent !== undefined ?
          <CodeBlock
            code={`${props.childrenContent}`}
            language={pLanguage}
            showLineNumbers={showLineNumbers}
          /> : null}
      </div>
      <div className={css.expanded}>
        <button onClick={onExpandButtonClicked}>
          <img src={arrowIcon} alt="Expand"/>
        </button>
      </div>
    </div>
  );
}

export default CodeContainer;
