export const removeTags = (html, tags, scope = 'global') => {
  tags.forEach((tag) => {
    let tmpHtml = document.createElement('div');
    tmpHtml.innerHTML = html;

    let toDelete = tmpHtml.getElementsByTagName(tag);
    if (toDelete.length) {
      console.log(`[${scope}] The following tags will be removed:`, tag);
      for (let i = toDelete.length - 1; i >= 0; i--) {
        toDelete[i].parentNode.removeChild(toDelete[i]);
      }
    }

    html = tmpHtml.innerHTML;
  });

  return html;
};

export const appendToContent = (target, content) => {
  if (content instanceof HTMLElement) {
    target.appendChild(content);
  } else if (content) {
    target.insertAdjacentHTML('beforeend', content);
  }
}

export const decodeHTML = (value) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(`${value}`, 'text/html');
  const html = doc.body.textContent;
  return html.trim();
}
